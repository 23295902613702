<template>
	<div class="g-block g-block--medium">
		<h3>New Users</h3>
		<span class="g-block__num">{{ getComponents.analytics.RevenueBlock.revenue }}</span>
	</div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  computed: {
        ...mapGetters("client", ["getClientSlug", "getSocial", "getComponents"]),
    },
    watch:{
        'getSocial.tokens.google': {
        deep: true,
            async handler(newValue) {
                try{
                    await this.init(newValue);
                }catch(error){
                    console.log('watch error catch', error);
                }
            }
        }
    },
    async mounted(){
        try{
            await this.init(this.getSocial.tokens.google);
        }catch(error){
            console.log('mounted error catch', error);
        }
    },
    methods:{
        async init(data){
            try{
                  const GA4PropertyID = data.GA4PropertyID;
                  const propertyID = data.accountID;
                  const accessToken = data.accessToken;
                  if(accessToken && GA4PropertyID && propertyID){
                      await this.$store.dispatch('client/analyticsRevenueBlockInit');
                      this.componentHasAllData();
                  }
                }catch(error){
                    console.log('init error catch', error);
                }
        },
        componentHasAllData(){

          let newVal = {
            vue: 'analyticsComponents',
            component: 'RevenueBlock',
            val: true
          }

          this.$store.commit('client/setPageLoaded', newVal);

        },
    },
}
</script>
