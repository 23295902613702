<template>
    <div class="g-block g-block--small">
        <div class="conversion-rate conversion-rate--large" v-bind:class="this.getComponents.analytics.AverageSessionDurationBlock.avgSessionClass">
            <div class="grid-container">
                <div class="col"><p>{{ formatPercentage(this.getComponents.analytics.AverageSessionDurationBlock.avgSessionComparison) }}</p></div>
                <div class="col"><span class="arrow"><i class="fa-solid fa-arrow-up-long"></i></span></div>
            </div>
        </div>
        <h3>Average session duration</h3>
        <span class="g-block__num">{{ this.getComponents.analytics.AverageSessionDurationBlock.avgSessionVal }}</span>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  computed: {
        ...mapGetters("client", ["getClientSlug", "getSocial", "getComponents"]),
    },
    watch:{
        'getSocial.tokens.google': {
        deep: true,
            async handler(newValue) {
                try{
                    await this.init(newValue);
                }catch(error){
                    console.log('watch error catch', error);
                }
            }
        }
    },
    async mounted(){
        try{
            await this.init(this.getSocial.tokens.google);
        }catch(error){
            console.log('mounted error catch', error);
        }
    },
    methods:{
        async init(data){
            try{
                  const GA4PropertyID = data.GA4PropertyID;
                  const accountID = data.accountID;
                  const accessToken = data.accessToken;
                  if(accessToken && GA4PropertyID && accountID){
                      await this.$store.dispatch('client/analyticsAverageSessionDurationBlockInit');
                      this.componentHasAllData();
                  }
                }catch(error){
                    console.log('init error catch', error);
                }
        },
        componentHasAllData(){

          let newVal = {
            vue: 'analyticsComponents',
            component: 'AverageSessionDurationBlock',
            val: true
          }

          this.$store.commit('client/setPageLoaded', newVal);

        },
    },
}
</script>
