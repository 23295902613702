<template>
	<div class="g-block g-block--medium">
		<h3>Session by channel</h3>
		<ul class="g-channels" v-bind:style="'--total:' + getComponents.analytics.SessionByChannelBlock.channelsMax">
			<li>
				<div class="channel-label">Organic Search</div>
				<div class="channel-value">{{ getComponents.analytics.SessionByChannelBlock.channelsOrganic }}</div>
				<div class="channel-bar" v-bind:style="'--value:' + getComponents.analytics.SessionByChannelBlock.channelsOrganic"></div>
			</li>
			<li>
				<div class="channel-label">Paid Search</div>
				<div class="channel-value">{{ getComponents.analytics.SessionByChannelBlock.channelsPaid }}</div>
				<div class="channel-bar" v-bind:style="'--value:' + getComponents.analytics.SessionByChannelBlock.channelsPaid"></div>
			</li>
			<li>
				<div class="channel-label">Direct</div>
				<div class="channel-value">{{ getComponents.analytics.SessionByChannelBlock.channelsDirect }}</div>
				<div class="channel-bar" v-bind:style="'--value:' + getComponents.analytics.SessionByChannelBlock.channelsDirect"></div>
			</li>
			<li>
				<div class="channel-label">Referral</div>
				<div class="channel-value">{{ getComponents.analytics.SessionByChannelBlock.channelsReferral }}</div>
				<div class="channel-bar" v-bind:style="'--value:' + getComponents.analytics.SessionByChannelBlock.hannelsReferral"></div>
			</li>
		</ul>
	</div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  computed: {
        ...mapGetters("client", ["getClientSlug", "getSocial", "getComponents"]),
    },
    watch:{
        'getSocial.tokens.google': {
        deep: true,
            async handler(newValue) {
                try{
                    await this.init(newValue);
                }catch(error){
                    console.log('watch error catch', error);
                }
            }
        },
    },
    async mounted(){
        try{
            await this.init(this.getSocial.tokens.google);
        }catch(error){
            console.log('mounted error catch', error);
        }
    },
    methods:{
        async init(data){
            try{
                  const GA4PropertyID = data.GA4PropertyID;
                  const propertyID = data.accountID;
                  const accessToken = data.accessToken;
                  if(accessToken && GA4PropertyID && propertyID){
                      await this.$store.dispatch('client/analyticsSessionByChannelBlockInit');
                      this.componentHasAllData();
                  }
                }catch(error){
                    console.log('init error catch', error);
                }
        },
        componentHasAllData(){

          let newVal = {
          vue: 'analyticsComponents',
          component: 'SessionByChannelBlock',
          val: true
        }

        this.$store.commit('client/setPageLoaded', newVal);

        },
    },
}
</script>
