<template>
  <div class="g-sticky">
    <header class="g-header">
        <h2>Analytics</h2>
    </header>
    <div class="g-graph">
        <div class="g-graph__header">
            <p class="sessions">Sessions</p>
            <p class="number">{{ getComponents.analytics.HeaderGraphBlock.sessionsVal }}</p>
            <div class="conversion-rate conversion-rate--large" v-bind:class="getComponents.analytics.HeaderGraphBlock.conversionClass">
                <div class="grid-container">
                    <div class="col">
                        <p>{{ getComponents.analytics.HeaderGraphBlock.conversionAmount }}</p>
                    </div>
                    <div class="col">
                        <span class="arrow">
                            <i class="fa-solid fa-arrow-up-long"></i>
                        </span>
                    </div>
                </div>
            </div>
            <p class="suffix">than last month</p>
        </div>
        <apexchart ref="mainChart" height="320" type="area" :options="mainChartOptions" :series="mainSeries"></apexchart>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    computed: {
        ...mapGetters("client", ["getClientSlug", "getSocial", "getComponents"]),
    },
    watch:{
        'getSocial.tokens.google': {
        deep: true,
            async handler(newValue) {
                try{
                    await this.init(newValue);
                }catch(error){
                    console.log('watch error catch', error);
                }
            }
        }
    },
    async mounted(){
        try{
            await this.init(this.getSocial.tokens.google);
            // window.addEventListener('scroll', this.handleScroll);
        }catch(error){
            console.log('mounted error catch', error);
        }
    },
    methods:{
        async init(data){
            try{
                  const GA4PropertyID = data.GA4PropertyID;
                  const propertyID = data.accountID;
                  const accessToken = data.accessToken;
                  if(accessToken && GA4PropertyID && propertyID){
                      const response = await this.$store.dispatch('client/analyticsHeaderGraphBlockInit');
                      this.displayData(response);

                  }
                }catch(error){
                    console.log('init error catch', error);
                }
        },
        displayData(updatedGoogleData){

            const metricDays = 'dailyHits';
            let dailyHits = []
            let dailyLabels = []
            
            /*
            * The data is either less than a day old, or has just been update so add the series to the graph.
            */
            for (var j = 30; j > 0; j--) {
                var savedDate = new Date();
                savedDate.setDate(savedDate.getDate() - j);
                let thedate = new Date(savedDate);
                savedDate = savedDate.toISOString().slice(0, 10);
                let currentDaysHits = updatedGoogleData.metrics[metricDays][savedDate]
                // Check that the current hits amount is a number, incase it's not in the list for any reason.
                dailyHits.push(currentDaysHits)
                let mon = thedate.toLocaleString('en-UK', {month: 'short'});
                let dd = thedate.getDate()
                dailyLabels.push(mon + ' ' + dd)
                this.$refs.mainChart.updateOptions({
                series: [{
                    name: 'Hits',
                    data: dailyHits,
                }],
                xaxis: {
                    categories: dailyLabels,
                },
                })
            }

            this.componentHasAllData();

        },
        componentHasAllData(){

          //setting to NULL so analytics.vue can watch it be set to NULL, reload the graph from there and then from there 
          //it will set it to TRUE and get rid of the page loader if the other components have finished loading. 

          let newVal = {
          vue: 'analyticsComponents',
          component: 'HeaderGraphBlock',
          val: true
          }

          this.$store.commit('client/setPageLoaded', newVal);

        },
        handleScroll() {
            // Any code to be executed when the window is scrolled
            this.isUserScrolling = (window.scrollY > 0);
            if ( window.scrollY > 30 ) {
                this.$refs.mainChart.updateOptions({
                  chart: {
                    height: 175,
                  },
                })
            } else {
                this.$refs.mainChart.updateOptions({
                  chart: {
                    height: 320,
                  },
                })
            }
        },
        toTop: function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
    },
    data() {
        return {
            updatedGoogleData: '',
            sessionsVal: '--',
            conversionAmount: '--',
            conversionClass: 'up',
            mainSeries: [],
            promisesMade: 0,
            errorsHappened: 0,
            promises: [],
            mainChartOptions: {
                chart: {
                    id: 'mainChart',
                    type: 'area',
                    height: 320,
                    animations: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                    selection: {
                        enabled: false,
                    },
                },
                colors: ['#5FB9E9'],
                fill: {
                    gradient: {
                        enabled: true,
                        opacityFrom: 0.35,
                        opacityTo: 0
                    }
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: '#4F516E',
                },
                yaxis: {
                    floating: true,
                    forceNiceScale: true,
                    min: 0,
                    labels: {
                        style: {
                            colors: ['#ffffff'],
                        },
                        offsetX: 40,
                    },
                },
                xaxis: {
                    axisBorder: {
                        color: '#4F516E',
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        show: false,
                    },
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                stroke: {
                    width: 2,
                    lineCap: 'round',
                    curve: 'smooth'
                },
                tooltip: {
                    theme: false,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        return '<div class="tooltip">' +
                        '<span class="tooltip__index"> ' + w.globals.categoryLabels[dataPointIndex] + '</span>' +
                        '<span class="tooltip__num"> ' + series[seriesIndex][dataPointIndex] + '</span>' +
                        '</div>'
                    },
                },
                noData: {
                  text: 'Loading...'
                }
            },
        }
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}

</script>
